import InstagramWidget from './modules/widgets/instagram';
import './modules/spinner';
import './modules/masks';

const instagram = new InstagramWidget({
  limit: {
    default: 8,
    notebook: 5,
    mobile: 1,
  },
});
